import { useState, useEffect, useContext } from 'react';
import { Box } from '@mui/system';
import { FC } from 'react';
import Style from '@jibin/common/style/Styles';
import { Button, Container, Grid, TextField, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { LoginContext } from '@jibin/common/Context/LoginContext';
import { Amplify, Auth } from 'aws-amplify';
import { LoginService } from '../Login/index.api';
import commonDetailSlice from 'src/store/reducers/commonReducer';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { CommonFunctions } from '@jibin/common/utils/Common';
import { MuiOtpInput } from 'mui-one-time-password-input';
import serviceDetailSlice from 'src/store/reducers/servicesReducer';
import { Service } from '../Common/Sidebar';
import { SidebarService } from '../Common/Sidebar/Sidebar.api';
import { LoadingButton } from '@mui/lab';
import { ArrowBack } from '@mui/icons-material';
import { MyAccountApi } from '../MyAccount/MyAccount.Api';
import { CompanyUserRoles } from '@jibin/common/utils/constants/ConstantName';
import { toast } from 'react-toastify';

export const Mfa: FC = () => {
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [buttonDisable, setButtonDisable] = useState(true);
  const { userData, setUserData, values } = useContext(LoginContext);
  const [isVerifyButtonLoading, setIsVerifyButtonLoading] = useState(false);
  const handleChange = (newValue) => {
    setOtp(newValue);
    setButtonDisable(true);
    setOtpError(false);
  };
  const locaton = useLocation();
  const adminMFAStatus = locaton?.state && locaton?.state;
  const navigate = useNavigate();
  const handleComplate = () => {
    setButtonDisable(false);
  };
  Amplify.configure({
    Auth: {
      userPoolId: CommonFunctions.getDefaultString(process.env.REACT_APP_COGNITO_USERPOOL_ID),
      userPoolWebClientId: CommonFunctions.getDefaultString(process.env.REACT_APP_COGNITO_CLIENT_ID)
    }
  });
  const varifyUser = async () => {
    if (
      adminMFAStatus?.mfa_status === 'MFA_SMS' ||
      adminMFAStatus?.mfa_status === 'MFA_AUTHENTICATOR'
    ) {
      try {
        setIsVerifyButtonLoading(true);
        const user = await MyAccountApi.GetUserData();
        if (adminMFAStatus?.mfa_status === 'MFA_SMS') {
          const data1 = await Auth.verifyCurrentUserAttributeSubmit('phone_number', otp);

          const noMFA = await Auth.setPreferredMFA(userData, 'NOMFA');
          const res = await Auth.setPreferredMFA(userData, 'SMS_MFA');
          const data = { ...user?.data?.data, isMfaSmsEnabled: true };
          const updateUserdetals = await MyAccountApi.PutUserData(data);
        } else if (adminMFAStatus?.mfa_status === 'MFA_AUTHENTICATOR') {
          const res = await Auth.verifyTotpToken(userData, otp);
          const noMFA = await Auth.setPreferredMFA(userData, 'NOMFA');
          const res2 = await Auth.setPreferredMFA(userData, 'TOTP');
          const data = { ...user?.data?.data, isMfaAppEnabled: true };
          const updateUserdetals = await MyAccountApi.PutUserData(data);
        }
        localStorage.setItem('cognito-token', JSON.stringify(userData.signInUserSession));
        localStorage.setItem('IsSessionActive', 'true');
        localStorage.setItem('isRedirectAuth', 'false');

        await getEntries();
      } catch (err) {
        setOtpError(true);
      } finally {
        setTimeout(() => {
          setIsVerifyButtonLoading(false);
        }, 1000);
      }
    }
    if (userData?.challengeName === 'SMS_MFA' || userData?.challengeName === 'SOFTWARE_TOKEN_MFA') {
      // You need to get the code from the UI inputs
      // and then trigger the following function with a button click
      const code = otp;
      // If MFA is enabled, sign-in should be confirmed with the confirmation code
      try {
        setIsVerifyButtonLoading(true);
        if (userData?.challengeName === 'SMS_MFA') {
          const loggedUser = await Auth.confirmSignIn(
            userData, // Return object from Auth.signIn()
            code, // Confirmation code
            'SMS_MFA' // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
          );
        } else {
          const loggedUser = await Auth.confirmSignIn(
            userData, // Return object from Auth.signIn()
            code, // Confirmation code
            'SOFTWARE_TOKEN_MFA' // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
          );
        }

        localStorage.setItem('cognito-token', JSON.stringify(userData.signInUserSession));
        localStorage.setItem('IsSessionActive', 'true');
        setErrorMessage(null);
        await getEntries();
      } catch (err) {
        setOtpError(true);
        if (err?.message?.includes('expired')) {
          setErrorMessage('Your code has expired, please log-in again');
        }
      } finally {
        setTimeout(() => {
          setIsVerifyButtonLoading(false);
        }, 1000);
      }
    } else {
      // The user directly signs in
    }
  };
  const GetServices = async (company_uuid: any, user_uuid: any) => {
    dispatch(serviceDetailSlice.actions.removeserviceDetails());

    try {
      const res = await SidebarService.GetServices(company_uuid, user_uuid);

      const commonObjects = Service.filter((obj1) =>
        res?.data?.data
          ?.filter((el: any) => (el.name == obj1.name && el.enabled) || obj1.isStatic)
          .some((obj2) => (obj2.name === obj1.name && obj2.enabled) || obj1.isStatic)
      );

      dispatch(serviceDetailSlice.actions.setserviceDetails(commonObjects));
    } catch (error) {}
  };

  const dispatch = useDispatch();
  async function getEntries() {
    await dispatch(commonDetailSlice.actions.removecommonDetails());
    LoginService.GetUserData().then(
      async (t: any) => {
        localStorage.setItem('device_id', uuidv4());
        await dispatch(commonDetailSlice.actions.setcommonDetails(t));
        GetServices(t?.data?.data?.company.company_uuid, t?.data?.data?.user_uuid);
        console.log(t?.data?.data?.company?.user_role);
        if (t.data.data.is_first_login) {
          navigate({ pathname: PageRouteConstants.Welcome, search: 'is_login=true' });
        } else {
          if (t?.data?.data?.company?.type == 'engineering') {
            navigate({ pathname: PageRouteConstants.CompanyDashBoard, search: 'is_login=true' });
          } else if (t?.data?.data?.company?.user_role === CompanyUserRoles.AUDITOR) {
            navigate({
              pathname: PageRouteConstants.GapAnalysisV2Questionnaire,
              search: 'is_login=true'
            });
          } else {
            navigate({ pathname: PageRouteConstants.OnBoarding, search: 'is_login=true' });
          }
        }
      },
      async (err: any) => {}
    );
  }
  useEffect(() => {
    if (!userData) {
      navigate(PageRouteConstants.Login);
    }
  }, []);

  useEffect(() => {
    if (otp.length === 6 && !isVerifyButtonLoading) {
      varifyUser();
    }
  }, [otp]);

  const handleResend = async () => {
    setOtp('');
    setOtpError(false);
    if (adminMFAStatus?.mfa_status === 'MFA_SMS') {
      const phoneNumber_update = await Auth.updateUserAttributes(userData, {
        phone_number: userData?.attributes?.phone_number
      });
      try {
        const data = await Auth.verifyCurrentUserAttribute('phone_number');
        toast.success('OTP Sent successfully');
      } catch (err) {}
    }
    if (userData && userData?.challengeName === 'SMS_MFA') {
      const userRes = await Auth.signIn(values.Email, values.password);
      // Update user object to confirm sign in with newly received code.
      toast.success('OTP Sent successfully');
      setUserData(userRes);
    }
  };
  return (
    <Box sx={{ mt: 10 }}>
      <Container maxWidth={'xl'}>
        <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 } }}>
            <Box
              sx={{
                ...Style.Auth.AuthLeftPart,
                display: 'flex',
                flexDirection: 'column',
                gap: 4
              }}
            >
              <Box>
                <Button variant="text" onClick={() => navigate(PageRouteConstants.Login)}>
                  <ArrowBack /> Back
                </Button>
              </Box>
              <Box>
                <Typography sx={{ fontWeight: 600, textAlign: 'justify' }} variant="subtitle2">
                  {' '}
                  Two-Factor authentication
                </Typography>
              </Box>
              {/* <Typography sx={{ textAlign: 'justify' }} variant="body1"> */}
              {/* Email Address{' '} */}
              {/* <>{locaton?.state?.email ? locaton?.state?.email : userData?.attributes?.email}</> */}
              {/* </Typography> */}
              <Typography sx={{ textAlign: 'justify' }} variant="body1">
                Enter the six digit authorization code{' '}
                {userData?.challengeName === 'SMS_MFA'
                  ? 'sent to your phone'
                  : userData?.challengeName === 'SOFTWARE_TOKEN_MFA'
                    ? 'from authorization app'
                    : null}{' '}
                {adminMFAStatus?.mfa_status === 'MFA_SMS'
                  ? 'sent to your phone '
                  : adminMFAStatus?.mfa_status === 'MFA_AUTHENTICATOR'
                    ? 'from authorization app'
                    : null}
                <>
                  {userData?.challengeParam?.CODE_DELIVERY_DESTINATION}
                  {adminMFAStatus?.mfa_status === 'MFA_SMS'
                    ? userData?.attributes?.phone_number
                    : null}
                </>
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <MuiOtpInput
                    value={otp}
                    autoFocus
                    onKeyDown={(e: any) => e.key == 'Enter' && varifyUser()}
                    onComplete={handleComplate}
                    length={6}
                    onChange={handleChange}
                    TextFieldsProps={{
                      error: otpError && !isVerifyButtonLoading,
                      autoComplete: 'off'
                    }}
                  />
                  {otpError && !isVerifyButtonLoading && (
                    <Typography sx={{ color: '#EF4444', fontSize: '14px' }}>
                      {errorMessage ? errorMessage : 'Invalid code entered. Please try again.'}
                    </Typography>
                  )}
                </Box>
                {(userData?.challengeName === 'SMS_MFA' ||
                  adminMFAStatus?.mfa_status === 'MFA_SMS') && (
                  <Typography
                    variant="body1"
                    sx={{ fontSize: '14px', textAlign: 'justify', letterSpacing: 0 }}
                  >
                    It may take a minute to receive your code. Haven't received it?
                    <span onClick={handleResend} style={{ color: '#436bf2', cursor: 'pointer' }}>
                      {' '}
                      Resend a new code.
                    </span>
                  </Typography>
                )}
                <LoadingButton
                  loading={isVerifyButtonLoading}
                  onClick={varifyUser}
                  disabled={buttonDisable}
                  variant="contained"
                  fullWidth
                >
                  Verify
                </LoadingButton>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
            <Box sx={Style.Auth.AuthInfographics}>
              <img alt="" src="/images/elephant-with-men.png" />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
