export class Error {
  code: number;
  message: string;
}

export class Owner {
  first_name: string;
  last_name: string;
  ico: string;
  email: string;
  user_uuid: string;
}

export class Policy {
  name: string;
  version: number;
  last_draft_date: Date;
  status: string;
  policy_uuid: string;
  owner: Owner;
}

export class ApiResponse {
  error: Error;
  data: Policy[];
}
