import MoreHorizIcon from '@jibin/common/icons/MoreHoriz/MoreHoriz';
import Style from '@jibin/common/style/Styles';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { More } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import { withStyles } from '@mui/material/styles';
import {
  TableRow,
  TableCell,
  Typography,
  Box,
  Button,
  IconButton,
  MenuItem,
  Divider,
  Switch,
  Checkbox
} from '@mui/material';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@jibin/common/icons/Delete/Delete';
import ArchiveIcon from '@jibin/common/icons/ArchiveIcon/Archive';
import { PoliciesAndProceduresApi } from '../Policys.Api';
import { useSelector, shallowEqual } from 'react-redux';
import theme from '@jibin/common/style/core/config/theme';
import Shareicon from '@jibin/common/icons/Shareicon/Shareicon';
import EditIcon from '@jibin/common/icons/Edit/Edit';
import EditIconForPolicy from '@jibin/common/icons/EditIconForPolicy';
import PrintIcon from '@jibin/common/icons/PrintIcon';
import EyeIcon from '@jibin/common/icons/EyeIcon';

import DownloadIcon from '@jibin/common/icons/DownloadIcon/DownloadIcon';

import { notifySuccess } from '@jibin/common/services/http/http-common';

import { SuccessConfirmation } from '@jibin/common/components/ConformationModel/SuccessConfirmation';
import CopyUrl from '@jibin/common/components/ConformationModel/CopyUrl';

import { DeleteTypesEnum } from '@jibin/common/utils/enums/deleteTypeEnums';
import ShareIconForPolicy from '@jibin/common/icons/ShareIconForPolicy';
import { CompanyUserRoles } from '@jibin/common/utils/constants/ConstantName';

var debounce = require('lodash.debounce');

const PolicyRow = (prop: any) => {
  const { row, isRefresh, setIsRefresh, isSelected, index, handleClickCheck } = prop;

  const [anchorEl, setAnchorEl] = useState(null);
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const getPolicy = async (id, version) => {
    const res = await PoliciesAndProceduresApi.GetPolicyById(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid,
      id,
      version
    );
    return res;
  };
  const copyUrl = (id) => {
    navigator.clipboard.writeText(
      `${window.location.hostname}${PageRouteConstants.PoliciesAndProceduresTemplate + id}`
    );
    // window.location.href

    notifySuccess('Copied');
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const downloadPolicy = async (id, policyName, version) => {
    try {
      const res = await PoliciesAndProceduresApi.DownloadPolicy(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        id,
        version
      );
      const blob = new Blob([res.data], { type: res.headers['content-type'] });

      // Creating an object for downloading url
      const url = window.URL.createObjectURL(blob);
      const urlName = window.URL.createObjectURL(blob);

      const a = document.createElement('a');

      a.href = urlName;

      a.download = `${policyName}-${version}.docx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {}
  };

  const printHTMLString = async (id, version) => {
    const printerWindow = window.open('', 'Print Window', 'width=600, height=600'); // Open a new window for printing
    printerWindow.document.open();
    const res: any = await getPolicy(id, version);

    printerWindow.document.write(res?.data?.data.document);
    printerWindow.document.close();
    printerWindow.print();
  };

  const handleSubmit = useCallback(
    debounce(
      (status: any = 'Published', e: any = '') => {
        let data = { status: status, comment: e };
        PoliciesAndProceduresApi.SubmitPolicyDocument(
          commonDetails.data.user_uuid,
          commonDetails.data.company.company_uuid,
          row?.policy_uuid,
          data
        ).then(
          (q: any) => {
            setIsRefresh(!isRefresh);
            handleClose();
            notifySuccess(`Document ${status.toLowerCase()} successfully`);
          },
          (err) => {}
        );
      },
      500,
      true
    ),
    [row]
  );

  const isItemSelected = isSelected(row);
  const labelId = `enhanced-table-checkbox-${index}`;

  const nevigate = useNavigate();

  return (
    <TableRow
      key={row.name}
      // onClick={(event) => handleClickCheck(event, row.name)}
      // role="checkbox"
      // aria-checked={isItemSelected}
      // tabIndex={-1}
      // selected={isItemSelected}
      sx={{
        '.MuiTableCell-root': {
          backgroundColor: '#FFFFFF ',

          borderBottom: '1px solid  #CCCCCC',
          borderTopRadius: '4px',
          borderTop: '1px solid  #CCCCCC',
          '&:last-child': {
            borderRight: '1px solid  #CCCCCC',
            borderTopRightRadius: '4px',
            borderBottomRightRadius: '4px'
          },
          '&:first-child': {
            borderLeft: '1px solid  #CCCCCC',
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px'
          }
        }
      }}
    >
      <TableCell
        key={row.name}
        onClick={(event) => handleClickCheck(event, row)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        padding="checkbox"
      >
        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{
            'aria-labelledby': labelId
          }}
        />
      </TableCell>

      <TableCell component="th" scope="row">
        {row.name}
      </TableCell>
      <TableCell>{row.version}</TableCell>
      <TableCell sx={{ minWidth: 150 }}>
        {moment(row.last_draft_date).isValid()
          ? momentTimezone(row.last_draft_date)
              .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
              .format('DD MMM YYYY')
              .toUpperCase()
          : ''}
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {row.owner.first_name + ' ' + row.owner.last_name}
          <Box component="span" sx={{ display: 'block' }}>
            {' '}
            {row.owner.email}
          </Box>{' '}
        </Typography>
      </TableCell>
      {/* <TableCell>
        <Typography variant="body2">
          {row.status_updated_by.first_name + ' ' + row.status_updated_by.last_name}
          <Box component="span" sx={{ display: 'block' }}>
            {' '}
            {row.status_updated_by.email}
          </Box>{' '}
        </Typography>
      </TableCell> */}
      <TableCell sx={{ minWidth: 150 }}>
        <Box>
          {row.status.toUpperCase() == 'APPROVED' && (
            <Box sx={{ ...Style.SiteListTable.StatusActive, bgcolor: 'common.green' }}></Box>
          )}
          {row.status.toUpperCase() == 'DRAFT' && (
            <Box sx={{ ...Style.SiteListTable.StatusActive, bgcolor: '#B4C3FA' }}></Box>
          )}
          {row.status.toUpperCase() == 'ARCHIVED' && (
            <Box sx={{ ...Style.SiteListTable.StatusActive, bgcolor: '#9D9D9D' }}></Box>
          )}
          {row.status.toUpperCase() == 'PUBLISHED' && (
            <Box
              sx={{
                ...Style.SiteListTable.StatusActive,
                bgcolor: '#10B981'
              }}
            ></Box>
          )}
          {row.status.toUpperCase() == 'REJECTED' && (
            <Box sx={{ ...Style.SiteListTable.StatusActive, bgcolor: 'error.main' }}></Box>
          )}
          {row.status}
        </Box>
      </TableCell>
      <TableCell align="right" sx={{ minWidth: 200 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'flex-end' }}>
          {/* <Box>
            <Button
              variant="contained"
              sx={{ whiteSpace: 'nowrap' }}
              onClick={() => {
                nevigate(PageRouteConstants.PoliciesAndProceduresTemplate + row.policy_uuid);
              }}>
              View Document
            </Button>
          </Box> */}

          <IconButton
            aria-label="Example"
            onClick={(e) => {
              handleClick(e);
            }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <MoreHorizIcon color="primary" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id={'account-menu'}
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5
              }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem
              onClick={() => {
                nevigate(PageRouteConstants.PoliciesAndProceduresTemplate + row.policy_uuid);
              }}
            >
              <EyeIcon sx={{ mr: 2, color: '#000000' }} />

              <Typography variant="body2">View</Typography>
            </MenuItem>
            <Divider sx={{ '&.MuiDivider-root': { m: 0 } }} />
            {commonDetails?.data?.company?.user_role !== CompanyUserRoles.AUDITOR && (
              <>
                <MenuItem
                  onClick={() => {
                    nevigate(
                      `${
                        PageRouteConstants.PoliciesAndProceduresTemplate + row.policy_uuid
                      }?isEdit=true`
                    );
                  }}
                >
                  <EditIconForPolicy sx={{ mr: 2, color: '#000000' }} />

                  <Typography variant="body2">Edit</Typography>
                </MenuItem>
                <Divider sx={{ '&.MuiDivider-root': { m: 0 } }} />
              </>
            )}
            <CopyUrl
              icon={<ShareIconForPolicy />}
              title="Copy and share this page"
              handleClose={handleClose}
              mainBtnTitle={'Share Link'}
              url={`${window.location.hostname}${
                PageRouteConstants.PoliciesAndProceduresTemplate + row.policy_uuid
              }`}
              handleConfirm={() => copyUrl(row?.policy_uuid)}
            />
            <Divider sx={{ '&.MuiDivider-root': { m: 0 } }} />
            <MenuItem onClick={() => printHTMLString(row.policy_uuid, row.version)}>
              <Box sx={{ mr: 2, fontSize: '24px' }}>
                <PrintIcon />
              </Box>

              <Typography variant="body2">Print</Typography>
            </MenuItem>
            <Divider sx={{ '&.MuiDivider-root': { m: 0 } }} />
            <MenuItem onClick={() => downloadPolicy(row.policy_uuid, row.name, row.version)}>
              <DownloadIcon sx={{ mr: 2, fontSize: '24px' }} />

              <Typography variant="body2">Download</Typography>
            </MenuItem>
            {commonDetails?.data?.company?.user_role !== CompanyUserRoles.AUDITOR && (
              <>
                <Divider sx={{ '&.MuiDivider-root': { m: 0 } }} />

                {row?.status.toUpperCase() == 'ARCHIVED' && (
                  <SuccessConfirmation
                    checked={false}
                    handleConfirm={(e) => {
                      handleSubmit('Draft');
                    }}
                    handleClose={() => handleClose()}
                    title="Confirm Action"
                    type={'icon'}
                    description={`Are you sure, do you want to unarchive "${row?.name}" document?`}
                    asking={``}
                    returnData={'Published'}
                    mainBtnTitle={'Unarchive'}
                    mainBtnType={'primary'}
                    cancelButtonText="Cancel"
                    conformButtonText="Confirm"
                  />
                )}
                {row?.status.toUpperCase() != 'ARCHIVED' && (
                  <SuccessConfirmation
                    checked={false}
                    handleClose={() => handleClose()}
                    handleConfirm={(e) => {
                      handleSubmit('Archived');
                    }}
                    title="Confirm Action"
                    type={'icon'}
                    description={`Are you sure, do you want to archive "${row?.name}" document?`}
                    asking={``}
                    returnData={'Published'}
                    mainBtnTitle={'Archive'}
                    mainBtnType={'primary'}
                    cancelButtonText="Cancel"
                    conformButtonText="Confirm"
                  />
                )}
                {row?.status.toUpperCase() != 'ARCHIVED' && (
                  <Divider sx={{ '&.MuiDivider-root': { m: 0 } }} />
                )}
                {row?.status.toUpperCase() != 'ARCHIVED' && (
                  <SuccessConfirmation
                    checked={row?.status?.toUpperCase() == 'PUBLISHED' ? true : false}
                    handleClose={() => handleClose()}
                    handleConfirm={(e) => {
                      if (row?.status?.toUpperCase() == 'PUBLISHED') {
                        handleSubmit('Draft');
                      } else {
                        handleSubmit('Published');
                      }
                    }}
                    title="Confirm Action"
                    type={'switch'}
                    description={`Are you sure, do you want to ${
                      row?.status?.toUpperCase() == 'PUBLISHED' ? 'draft' : 'publish'
                    } "${row?.name}" document?`}
                    asking={``}
                    returnData={'Published'}
                    mainBtnTitle={'Archive'}
                    mainBtnType={'primary'}
                    cancelButtonText="Cancel"
                    conformButtonText="Confirm"
                  />
                )}
              </>
            )}
          </Menu>
        </Box>
      </TableCell>
    </TableRow>
  );
};
export default PolicyRow;
