import * as React from 'react';
import { useState } from 'react';

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  styled,
  Box
} from '@mui/material';
import AccordianComponent from './AccordianComponent';

function CollapsibleTable({ trainingDataEnrollments }: any) {
  return (
    <div>
      <TableContainer
        sx={{ position: 'sticky', top: '86px', zIndex: '1', backgroundColor: '#fbfbff' }}
      >
        <Table sx={{ width: '100%', borderBottom: 'none', marginBottom: '0 !important' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '350px', borderBottom: 'none' }}>Modules</TableCell>
              <TableCell sx={{ width: '200px', borderBottom: 'none' }} align="left">
                Enrollment Date
              </TableCell>
              <TableCell sx={{ width: '200px', borderBottom: 'none' }}>Due Date</TableCell>
              <TableCell sx={{ width: '200px', borderBottom: 'none' }}>Status</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: '50px', mt: '16px' }}>
        {trainingDataEnrollments?.map((item) => <AccordianComponent item={item} />)}
      </Box>
    </div>
  );
}

export default CollapsibleTable;
