import { FC, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputAdornment,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  TextField,
  Typography
} from '@mui/material';

// import { Graph } from '@jibin/common/icons/Graph/Graph';
import { shallowEqual, useSelector } from 'react-redux';
import { SecurityAwarenessApi } from './SecurityAwareness.Api';
import SecurityAwarnessTemplate from './SecurityAwarnessTemplate';
import { NoDataAvailable } from './../Common/NoDataAvailable';
import DownloadIcon from '@jibin/common/icons/DownloadIcon/DownloadIcon';
import SearchIcon from '@jibin/common/icons/Search/Search';
import CollapsibleTable from './CollapseTable';
import TablePagination from '../Common/TablePagination';
import { EllipsisContent } from '@jibin/common/components/EllipsisContent';
import FilterCancelIcon from '@jibin/common/icons/FilterCancelIcon';
export const SecurityAwarness: FC = () => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 10;
  const MenuProps = {
    PaperProps: {
      style: {
        fontSize: '14px',
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150
      }
    }
  };
  const [page, setPage] = useState(0);
  const [listFilter, setListFilter] = useState({
    keyword: '',
    statuses: '',
    campaign_id: ''
  });
  const [filterData, setFilterData] = useState({
    statuses: ['']
  });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [isArrowButtonDisable, setIsArrowButtonDisable] = useState(false);
  const [isDropDownLoaded, setIsDropDownLoaded] = useState(true);
  const [totalCount, setTotalCount] = useState(null);
  const [pagination, setPagination] = useState({
    page_no: '1',
    page_size: '10'
  });
  const handleChangeMulti = (event: any) => {
    let {
      target: { value, name }
    } = event;
    setIsLoading(true);
    const filterValue = value.filter((el: any) => el != '');
    let string;
    if (filterValue.length == 0) {
      string = '';
      const item = { ...filterData };

      setListFilter({
        ...listFilter,
        statuses: string
      });
      item[name] = [''];

      setFilterData(item);
    } else {
      string = filterValue.join(',');
      setListFilter({
        ...listFilter,
        statuses: string
      });

      const item = { ...filterData };
      item[name] = typeof value === 'string' ? filterValue.split(',') : filterValue;

      setFilterData(item);
    }
  };
  const StatusList = [
    { key: 'Completed', value: 'Completed' },
    { key: 'In Progress', value: 'In Progress' },
    { key: 'Not Started', value: 'Not Started' },
    { key: 'Past Due', value: 'Past Due' },
    { key: 'Passed', value: 'Passed' },
    { key: 'All Statuses', value: '' }
  ];
  const [trainingData, setTrainingData] = useState([] as any);

  const GetTraining = useCallback(() => {
    SecurityAwarenessApi.trainingCampaigns(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        setTrainingData(q.data.data);
        setIsDropDownLoaded(false);
      },
      () => {
        setIsDropDownLoaded(false);
        setIsLoading(false);
      }
    );
  }, [commonDetails]);

  const [trainingDataEnrollments, setTrainingDataEnrollments] = useState([] as any);
  const renderValue = (selected: any, options: any) => {
    const selectedLabels = selected?.map((value) => {
      const selectedOption = options.find((option) => option.value === value);
      return selectedOption ? selectedOption.key : '';
    });

    return selectedLabels.join(', ');
  };
  const GetTrainingEnrollments = useCallback(
    (id) => {
      try {
        setIsArrowButtonDisable(true);
        SecurityAwarenessApi.trainingCampaignsEnrollments(
          commonDetails.data.user_uuid,
          commonDetails.data.company.company_uuid,
          listFilter.campaign_id || id,
          new URLSearchParams({
            ...pagination,
            keyword: listFilter.keyword,
            statuses: listFilter.statuses
          }).toString()
        ).then(
          (q: any) => {
            setTrainingDataEnrollments(q.data.data.data);
            setTotalCount(q?.data?.data?.pagination?.total_records);

            setPage(q?.data?.data?.pagination?.page_number - 1);
            setIsLoading(false);
          },
          () => {
            setIsLoading(false);
          }
        );
      } catch (error) {
      } finally {
        setTimeout(() => {
          setIsArrowButtonDisable(false);
        }, 1500);
      }
    },
    [commonDetails, listFilter, pagination]
  );
  useEffect(() => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page_no: String(page + 1),
      page_size: String(rowsPerPage)
    }));
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (trainingData.length > 0) {
      GetTrainingEnrollments(trainingData[0].campaign_id);
    }
  }, [GetTrainingEnrollments, trainingData]);
  const handleChange = (e: any) => {
    setIsLoading(true);
    setListFilter({ ...listFilter, [e.target.name]: e.target.value });
  };
  const handleClearFuntion = () => {
    let item = { ...filterData };
    setListFilter({
      keyword: '',
      statuses: '',
      campaign_id: trainingData[0]?.campaign_id
    });

    item = { ...item, statuses: [''] };
    setFilterData(item);
  };
  useEffect(() => {
    GetTraining();
  }, [GetTraining]);

  return (
    <Box>
      <SecurityAwarnessTemplate>
        <Box
          sx={{
            px: 4,
            py: 3,
            width: 1
          }}
        >
          <Box>
            {isDropDownLoaded ? (
              <Box sx={{ my: 2 }}>
                <Skeleton sx={{ p: 3 }} variant="rectangular" />
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  borderRadius: '8px',

                  py: 2,
                  gap: 1,
                  flexWrap: 'wrap'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 1
                  }}
                >
                  <Box sx={{ py: 2, display: 'flex', gap: 3, alignItems: 'center' }}>
                    <TextField
                      placeholder="Search"
                      hiddenLabel
                      fullWidth
                      size="small"
                      value={listFilter.keyword}
                      onChange={handleChange}
                      name="keyword"
                      sx={{ backgroundColor: '#FFFFFF', width: '250px' }}
                      inputProps={{ sx: { fontSize: '14px', color: '#696969' } }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        )
                      }}
                    />
                    <FormControl size="small" sx={{ minWidth: 200 }}>
                      <Select
                        size="small"
                        displayEmpty
                        id="type"
                        defaultValue={trainingData[0]?.campaign_id}
                        name="campaign_id"
                        value={
                          listFilter.campaign_id != ''
                            ? listFilter.campaign_id
                            : trainingData[0]?.campaign_id
                        }
                        onChange={handleChange}
                        sx={{
                          fontSize: '14px',
                          color: '#696969',

                          backgroundColor: '#FFFFFF'
                        }}
                        MenuProps={{
                          sx: {
                            maxWidth: 200,
                            maxHeight: 200
                          }
                        }}
                        inputProps={{
                          'aria-label': 'Without label'
                        }}
                      >
                        {trainingData?.map((el: any) => (
                          <MenuItem sx={{ fontSize: '14px' }} value={el?.campaign_id}>
                            <EllipsisContent variant="body2" text={el?.campaign_name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ maxWidth: '200px' }}>
                      <Select
                        size="small"
                        displayEmpty
                        multiple
                        name="statuses"
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        value={filterData.statuses}
                        onChange={handleChangeMulti}
                        input={
                          <OutlinedInput
                            sx={{
                              py: '5.5px'
                            }}
                          />
                        }
                        renderValue={(selected) => renderValue(selected, StatusList)}
                        MenuProps={MenuProps}
                        sx={{
                          py: 0.35,
                          fontSize: '14px',
                          color: '#696969',
                          '.MuiSelect-select': {
                            py: '5.5px'
                          },
                          backgroundColor: '#FFFFFF'
                        }}
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        {StatusList.map((name, index) => (
                          <MenuItem
                            sx={{
                              fontSize: '14px',
                              display: !name.value ? 'none' : 'flex',

                              py: 0,
                              '.MuiMenuItem-root': {
                                py: '0px'
                              }
                            }}
                            key={name.key}
                            value={name.value}
                            autoFocus={index === 0}
                          >
                            <Checkbox checked={filterData.statuses.indexOf(name.value) > -1} />
                            <ListItemText
                              sx={{ '.MuiTypography-root': { fontSize: '14px' } }}
                              primary={name.key}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Box sx={{ width: 120 }}>
                      <Button
                        onClick={handleClearFuntion}
                        sx={{
                          display: 'flex',
                          gap: 0.5,
                          alignItems: 'center',
                          py: 0.5,
                          whiteSpace: 'nowrap'
                        }}
                        variant="text"
                      >
                        {' '}
                        <FilterCancelIcon /> Clear filter
                      </Button>
                    </Box>
                  </Box>
                </Box>

                {process.env.REACT_APP_SHOW_PARTIAL_FEATURES === 'true' && (
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Button variant="outlined">
                      <DownloadIcon sx={{ fontSize: '18px', mr: 0.5 }} /> Download List
                    </Button>
                  </Box>
                )}
              </Box>
            )}
          </Box>
          {isLoading ? (
            <Box>
              <Skeleton sx={{ p: 3 }} variant="rectangular" />
            </Box>
          ) : trainingDataEnrollments.length == 0 || !trainingDataEnrollments ? (
            <NoDataAvailable title="No Data Available" message="" />
          ) : (
            <Box>
              <CollapsibleTable trainingDataEnrollments={trainingDataEnrollments} />
              <Box sx={{ mt: 2 }}>
                <TablePagination
                  totalCount={totalCount}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  isArrowButtonDisable={isArrowButtonDisable}
                />
              </Box>
            </Box>
          )}
        </Box>
      </SecurityAwarnessTemplate>
    </Box>
  );
};
