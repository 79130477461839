import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import React, { FC } from 'react';
import CloseIcon from '../../icons/Close/Close';
import WarningIcon from '../../icons/Warning/Warning';
import DeleteIcon from '@jibin/common/icons/Delete/Delete';
import { DeleteTypesEnum } from '../../utils/enums/deleteTypeEnums';
import { Divider, MenuItem, Switch } from '@mui/material';
import { shallowEqual, useSelector } from 'react-redux';
import ArchiveIcon from '@jibin/common/icons/ArchiveIcon/Archive';
import CheckIcon from '@jibin/common/icons/CheckIcon/CheckIcon';
import CheckCircleIcon from '@jibin/common/icons/CheckCircle/CheckCircle';
import theme from '@jibin/common/style/core/config/theme';
import ShareIconForPolicy from '@jibin/common/icons/ShareIconForPolicy';
import CopyIcon from '@jibin/common/icons/CopyIcon';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { notifySuccess } from '@jibin/common/services/http/http-common';
export interface ConformationModel {
  title?: string;
  handleClose: any;
  mainBtnTitle: any;
  url: any;
  icon: any;
  handleConfirm(returnData: any): void;
}

const CopyUrl: FC<ConformationModel> = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    props.handleClose();
  };
  const handleConfirm = () => {
    props?.handleConfirm();
    props.handleClose();
    setOpen(false);
  };
  const handleChange = (e: any) => {
    if (!e.target.checked) {
      handleClickOpen();
    }
  };
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  return (
    <>
      {props?.mainBtnTitle ? (
        <MenuItem onClick={handleClickOpen}>
          <Box sx={{ mr: 2, fontSize: '24px' }}>{props.icon}</Box>

          <Typography variant="body2">{props?.mainBtnTitle}</Typography>
        </MenuItem>
      ) : (
        <Box sx={{ cursor: 'pointer' }} onClick={handleClickOpen}>
          {props.icon}
        </Box>
      )}

      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            px: { xs: 1, md: 2 },
            py: { xs: 0.5, md: 1 },
            bgcolor: 'primary.main100'
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',

                fontWeight: '600',
                maxWidth: 'calc(100% - 40px)',
                p: 1
              }}
            >
              {props.title}
            </Typography>
            <IconButton sx={{ color: 'text.primary' }} aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
          <Box
            sx={{
              border: '1px dashed #CCCCCC',
              p: 1,
              bgcolor: '#FBFBFF',
              borderRadius: '8px',
              display: 'flex',
              gap: 2
            }}
          >
            <Box>
              <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
                {props?.url}
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={handleConfirm}>
                <CopyIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CopyUrl;
