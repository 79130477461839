import SvgIcon from '@mui/material/SvgIcon';

export default function GapAnalysisIcon(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 21H21M6 18V9.99998M10 18V9.99998M14 18V9.99998M18 18V9.99998M20 6.99998L12.424 2.26498C12.2702 2.16884 12.1933 2.12077 12.1108 2.10203C12.0379 2.08546 11.9621 2.08546 11.8892 2.10203C11.8067 2.12077 11.7298 2.16884 11.576 2.26498L4 6.99998H20Z"
        stroke="#fff"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
// stroke = '#436AF3';
export function GapAnalysisIconBlue(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 21.4552H21M6 18.4552V10.4552M10 18.4552V10.4552M14 18.4552V10.4552M18 18.4552V10.4552M20 7.45518L12.424 2.72018C12.2702 2.62404 12.1933 2.57597 12.1108 2.55723C12.0379 2.54066 11.9621 2.54066 11.8892 2.55723C11.8067 2.57597 11.7298 2.62404 11.576 2.72018L4 7.45518H20Z"
        stroke="#436AF3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
