import React, { FC, useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Link from '@mui/material/Link';
import Style from '@jibin/common/style/Styles';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';

import { Skeleton } from '@mui/material';
import { useSelector, shallowEqual } from 'react-redux';
import FrameworkControl from '../FrameWorks/FrameworkControl';
import { FrameworksApi } from '../FrameWorks/Frameworks.Api';
import HeadingHeader from '../Common/HeadingHeader';

const MenuItems = [
  { name: 'Training Campaign', class: '', route: PageRouteConstants.SecurityAwarenessResult },
  { name: 'Phishing Campaign', class: '', route: PageRouteConstants.SecurityAwarenessParticupant }
];

export const SecurityAwarnessTemplate = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const [frameworksList, setFrameworksList] = useState({} as any);
  const [framework, setFramework] = useState({} as any);
  const [frameworkId, setFrameworkId] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  //const [useParams, setUseParam] = useSearchParams();

  return (
    <>
      <HeadingHeader
        subtitle1={'Security Awareness'}
        subtitle2={'Employees are the first line of defense against cyber threats'}
      />

      <List sx={Style.CompanySetting.MenuList}>
        {MenuItems?.map((currenElement, index) => (
          <ListItem disablePadding key={index} sx={{ width: 'auto' }}>
            <Link
              onClick={() => {
                navigate(currenElement?.route);
              }}
              style={{ cursor: 'pointer' }}
              className={location.pathname === currenElement?.route ? 'active' : ''}
              sx={Style.CompanySetting.MenuListItem}
            >
              {currenElement.name}
            </Link>
          </ListItem>
        ))}
      </List>
      {props.children}
    </>
  );
};

export default SecurityAwarnessTemplate;
