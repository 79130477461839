import { useState, useCallback, useEffect } from 'react';
import {
  Box,
  TextField,
  InputAdornment,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
  Button,
  Skeleton,
  SwipeableDrawer
} from '@mui/material';
import SearchIcon from '@jibin/common/icons/Search/Search';
import FilterCancelIcon from '@jibin/common/icons/FilterCancelIcon';
import Style from '@jibin/common/style/Styles';
import LicenseListTable from './LicenseListTable';
// import LicenseDialog from './Models/LicenseDialog';
import { shallowEqual, useSelector } from 'react-redux';
import { CompanySettingsService } from '../CompanySettings.Api';
import LicenseDialog from './LicenseDialog';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

const Licenses = () => {
  const [licenseListData, setLicenseListData] = useState(null);
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [servicesOption, setServicesOption] = useState([] as any);
  const [rowData, setRowData] = useState(null);
  const [editLicense, setEditLicense] = useState(false);
  const [globalClose, setGloblClose] = useState(false);
  const [vendors, setVendors] = useState([] as any);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(null);
  const [pagination, setPagination] = useState({
    page_no: '1',
    page_size: '10'
  });
  const [state, setState] = useState({
    right: false
  });

  const [userFilterform, setUserFilterform] = useState({
    keyword: '',
    vendors: '',
    status: ''
  });

  const allStatusItems = [
    { key: 'Active', value: 'Active' },
    { key: 'Inactive', value: 'Inactive' },
    { key: 'Expired', value: 'Expired' },
    { key: 'All Statuses', value: '' }
  ];

  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const GetServiceFilterOption = useCallback(async () => {
    try {
      const res = await CompanySettingsService.licenseServices(
        commonDetails?.data?.company.company_uuid,
        commonDetails?.data?.user_uuid
      );
      const ProviderListForFilter = res.data.data.map((el) => {
        return {
          key: `${el.code}`,
          value: el.services_uuid,
          name: el.name,
          tooltip: el.name,
          tools: el.tools
        };
      });
      setServicesOption(ProviderListForFilter);
    } catch (error) {}
  }, [commonDetails]);

  useEffect(() => {
    GetServiceFilterOption();
  }, [GetServiceFilterOption]);

  const GetVendors = useCallback(async () => {
    try {
      const res = await CompanySettingsService.GetVendors(
        commonDetails?.data?.company.company_uuid
      );
      const ProviderListForFilter = res?.data?.data?.map((el) => {
        return {
          key: `${el?.name}`,
          value: el?.vendor_uuid
        };
      });
      ProviderListForFilter.push({
        key: 'All Vendors',
        value: ''
      });
      setVendors(ProviderListForFilter);
    } catch (error) {}
  }, [commonDetails]);

  useEffect(() => {
    GetVendors();
  }, [GetVendors]);

  const handleClearFuntion = () => {
    setUserFilterform({
      keyword: '',
      vendors: '',
      status: ''
    });
  };

  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 10;
  const MenuProps = {
    PaperProps: {
      style: {
        fontSize: '14px',
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 180
      }
    }
  };

  const renderValue = (selected: any, options: any) => {
    const selectedLabels = selected?.map((value) => {
      const selectedOption = options.find((option) => option.value === value);
      return selectedOption ? selectedOption.key : '';
    });

    return selectedLabels.join(', ');
  };

  const handleSearch = (e) => {
    let updatedValue = e.target.value;
    if (Array.isArray(e.target.value)) {
      updatedValue = e.target.value.filter(Boolean).join(',');
    }
    setUserFilterform({ ...userFilterform, [e.target.name]: updatedValue });
  };

  const GetLicenses = useCallback(() => {
    CompanySettingsService.getLicense(
      commonDetails.data.company.company_uuid,
      commonDetails.data.user_uuid,
      new URLSearchParams({
        ...userFilterform,
        ...pagination
      }).toString()
    ).then(
      (q: any) => {
        setLicenseListData(q.data?.data?.data);
        setTotalCount(q?.data?.data?.pagination?.total_records);
        setIsSkeleton(false);
      },
      (err: any) => {
        setIsSkeleton(false);
      }
    );
  }, [commonDetails, userFilterform, pagination.page_no, pagination.page_size]);

  useEffect(() => {
    GetLicenses();
  }, [GetLicenses, userFilterform]);

  useEffect(() => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page_no: String(page + 1),
      page_size: String(rowsPerPage)
    }));
  }, [page, rowsPerPage]);

  const toggleDrawer = (anchor: Anchor, open: boolean, row?: any) => {
    if (!open) {
      setRowData(null);
      setEditLicense(false);
    }
    // setRowData(row);
    setState({ ...state, [anchor]: open });
  };

  return (
    <>
      {isSkeleton ? (
        <Box sx={{ px: 4, py: 3 }}>
          <Skeleton sx={{ p: 5 }} />
        </Box>
      ) : (
        <Box sx={Style.CompanySettingPage.CompanySettingPageWrapper}>
          <Box sx={{ ...Style.SiteListTable.SiteListTableWrapper, overflowX: 'initial' }}>
            <Box
              sx={{
                display: 'flex',
                gap: 3,
                flexWrap: 'nowrap',
                alignItems: 'center',
                mb: '32px'
              }}
            >
              <TextField
                placeholder="Search Services or Tools"
                hiddenLabel
                size="small"
                name="keyword"
                value={userFilterform.keyword}
                onChange={handleSearch}
                sx={{
                  input: { backgroundColor: '#FFFFFF' },
                  '.MuiOutlinedInput-root': {
                    bgcolor: '#FFFFFF'
                  },
                  width: 250
                }}
                inputProps={{ sx: { fontSize: '14px', color: '#696969' } }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment sx={{ bgcolor: '#FFFFFF' }} position="end">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
              <FormControl sx={{ width: 200 }}>
                <Select
                  size="small"
                  displayEmpty
                  multiple
                  name="vendors"
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  value={userFilterform.vendors.split(',')}
                  onChange={handleSearch}
                  input={
                    <OutlinedInput
                      sx={{
                        py: '5.5px'
                      }}
                    />
                  }
                  renderValue={(selected) => renderValue(selected, vendors)}
                  MenuProps={MenuProps}
                  sx={{
                    py: 0.35,
                    fontSize: '14px',
                    color: '#696969',
                    '.MuiSelect-select': {
                      py: '5.5px'
                    },
                    minWidth: { xxs: 0, md: '150px' },
                    backgroundColor: '#FFFFFF'
                  }}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {vendors?.map((name, index) => (
                    <MenuItem
                      sx={{
                        fontSize: '14px',
                        display: !name.value ? 'none' : 'flex',

                        py: 0,
                        '.MuiMenuItem-root': {
                          py: '0px'
                        }
                      }}
                      key={name.key}
                      value={name.value}
                      autoFocus={index === 0}
                    >
                      <Checkbox checked={userFilterform.vendors.indexOf(name.value) > -1} />
                      <ListItemText
                        sx={{ '.MuiTypography-root': { fontSize: '14px' } }}
                        primary={name.key}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: 200 }}>
                <Select
                  size="small"
                  displayEmpty
                  multiple
                  name="status"
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  value={userFilterform.status.split(',')}
                  onChange={handleSearch}
                  input={
                    <OutlinedInput
                      sx={{
                        py: '5.5px'
                      }}
                    />
                  }
                  renderValue={(selected) => renderValue(selected, allStatusItems)}
                  MenuProps={MenuProps}
                  sx={{
                    py: 0.35,
                    fontSize: '14px',
                    color: '#696969',
                    '.MuiSelect-select': {
                      py: '5.5px'
                    },
                    minWidth: { xxs: 0, md: '150px' },
                    backgroundColor: '#FFFFFF'
                  }}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {allStatusItems?.map((name, index) => (
                    <MenuItem
                      sx={{
                        fontSize: '14px',
                        display: !name.value ? 'none' : 'flex',

                        py: 0,
                        '.MuiMenuItem-root': {
                          py: '0px'
                        }
                      }}
                      key={name.key}
                      value={name.value}
                      autoFocus={index === 0}
                    >
                      <Checkbox checked={userFilterform.status.split(',').includes(name.value)} />
                      <ListItemText
                        sx={{ '.MuiTypography-root': { fontSize: '14px' } }}
                        primary={name.key}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                onClick={handleClearFuntion}
                sx={{ display: 'flex', gap: 0.5, alignItems: 'center', py: 0.5 }}
                variant="text"
              >
                {' '}
                <FilterCancelIcon /> Clear filter
              </Button>
              <Box sx={{ ml: 'auto' }}>
                {/* <LicenseDialog
                  servicesOption={servicesOption}
                  commonDetails={commonDetails}
                  GetLicenses={GetLicenses}
                  setGloblClose={setGloblClose}
                  setEditLicense={setEditLicense}
                  editLicense={editLicense}
                  rowData={rowData}
                  setRowData={setRowData}
                /> */}
                <Button variant="contained" onClick={() => toggleDrawer('right', true)}>
                  Add License
                </Button>
              </Box>
            </Box>
            <LicenseListTable
              licenseListData={licenseListData}
              totalCount={totalCount}
              page={page}
              globalClose={globalClose}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              commonDetails={commonDetails}
              GetLicenses={GetLicenses}
              setEditLicense={setEditLicense}
              setRowData={setRowData}
              rowData={rowData}
              toggleDrawer={toggleDrawer}
            />
          </Box>

          <SwipeableDrawer
            BackdropProps={{ style: { backgroundColor: 'black', opacity: 0.4 } }}
            sx={{
              '.MuiDrawer-paper': {
                boxShadow: 'none'
              }
            }}
            anchor={'right'}
            open={state['right']}
            onClose={() => toggleDrawer('right', false)}
            onOpen={() => toggleDrawer('right', true, rowData)}
          >
            {!isSkeleton && (
              <LicenseDialog
                closePopup={() => toggleDrawer('right', false)}
                servicesOption={servicesOption}
                commonDetails={commonDetails}
                GetLicenses={GetLicenses}
                setGloblClose={setGloblClose}
                setEditLicense={setEditLicense}
                editLicense={editLicense}
                rowData={rowData}
                setRowData={setRowData}
              />
            )}
          </SwipeableDrawer>
        </Box>
      )}
    </>
  );
};

export default Licenses;
