import { useState, useCallback, useEffect } from 'react';
import DownloadIcon from '@jibin/common/icons/DownloadIcon/DownloadIcon';
import Style from '@jibin/common/style/Styles';
import { LoadingButton } from '@mui/lab';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Select,
  FormControl,
  TextField,
  InputAdornment,
  OutlinedInput,
  ListItemText,
  MenuItem,
  TableContainer,
  Paper,
  Skeleton
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { FC } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { PlanAndBillingApi } from './PlanAndBilling.Api';
import PlanAndBillingRow from './PlanAndBillingRow';
import SearchIcon from '@jibin/common/icons/Search/Search';
import FilterCancelIcon from '@jibin/common/icons/FilterCancelIcon';
import TablePagination from '../../Common/TablePagination';
import { NoDataAvailable, NoSearchDataAvailable } from '../../Common/NoDataAvailable';

export const PlanAndBilling: FC = (props: any) => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const [invoicesData, setInvoicesData] = useState([]);
  const [initialLength, setInitialLength] = useState(false);
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(null);
  const [filterData, setFilterData] = useState({
    keyword: '',
    status: ''
  });
  const [pagination, setPagination] = useState({
    page_no: '1',
    page_size: '10'
  });
  const handleContactUs = async () => {
    try {
      const res = PlanAndBillingApi.ContactUs(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        ''
      ).then((q) => {
        window.open('https://www.redesign-group.com/contact-us', '_blank');
      });
    } catch (error) {}
  };
  const billingStatusItems = [
    { key: 'Paid', value: 'Paid' },
    { key: 'Pending', value: 'Pending' },
    { key: 'Not Paid', value: 'Not Paid' },
    { key: 'All Statuses', value: '' }
  ];
  const renderValue = (selected: any, options: any) => {
    const selectedLabels = selected?.map((value) => {
      const selectedOption = options.find((option) => option.value === value);
      return selectedOption ? selectedOption.key : '';
    });

    return selectedLabels.join(', ');
  };
  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 10;
  const MenuProps = {
    PaperProps: {
      style: {
        fontSize: '14px',
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 180
      }
    }
  };
  const handleClearFuntion = () => {
    setFilterData({
      keyword: '',
      status: ''
    });
  };
  const handleChange = (e: any) => {
    let updatedValue = e.target.value;
    if (Array.isArray(e.target.value)) {
      updatedValue = e.target.value.filter(Boolean).join(',');
    }
    setFilterData({ ...filterData, [e.target.name]: updatedValue });
  };
  const GetInvoices = useCallback(async () => {
    try {
      const queryParams = new URLSearchParams({
        ...filterData,
        ...pagination
      }).toString();
      const res = await PlanAndBillingApi.Invoices(
        commonDetails.data.company.company_uuid,
        commonDetails.data.user_uuid,
        queryParams
      );
      setTotalCount(res?.data?.pagination?.total_records);
      if (isSkeleton && res?.data?.data) {
        setInitialLength(true);
      }

      setInvoicesData(res?.data?.data);
      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  }, [commonDetails, filterData, pagination.page_no, pagination.page_size]);

  useEffect(() => {
    GetInvoices();
  }, [GetInvoices, filterData]);

  useEffect(() => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page_no: String(page + 1),
      page_size: String(rowsPerPage)
    }));
  }, [page, rowsPerPage]);
  const downloadPDF = async (row) => {
    try {
      const res = await PlanAndBillingApi.GetInvoicePDF(
        commonDetails.data.company.company_uuid,
        commonDetails.data.user_uuid,
        row?.invoice_id
      );
      const blob = new Blob([res?.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${row?.name}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (err) {}
  };
  return (
    <>
      {isSkeleton ? (
        <Box sx={{ px: 4, py: 3 }}>
          <Skeleton sx={{ p: 5 }} />
        </Box>
      ) : (
        <>
          <Box sx={Style.CompanySettingPage.CompanySettingPageWrapper}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: 1,
                alignItems: 'center',
                mt: 3
              }}
            >
              {/* <Typography variant="subtitle2" sx={{ fontWeight: 600, fontSize: '19px' }}>
              Billing History fff
            </Typography> */}
              <Box sx={{ display: 'flex', gap: 3, flexWrap: 'nowrap', alignItems: 'center' }}>
                <TextField
                  placeholder="Search"
                  hiddenLabel
                  size="small"
                  name="keyword"
                  value={filterData?.keyword}
                  onChange={handleChange}
                  sx={{
                    input: { backgroundColor: '#FFFFFF' },
                    '.MuiOutlinedInput-root': {
                      bgcolor: '#FFFFFF'
                    }
                  }}
                  inputProps={{ sx: { fontSize: '14px', color: '#696969' } }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment sx={{ bgcolor: '#FFFFFF' }} position="end">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
                <FormControl sx={{ width: 160 }}>
                  <Select
                    size="small"
                    displayEmpty
                    multiple
                    name="status"
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    value={filterData.status.split(',')}
                    onChange={handleChange}
                    input={<OutlinedInput />}
                    renderValue={(selected) => renderValue(selected, billingStatusItems)}
                    MenuProps={MenuProps}
                    sx={{
                      py: 0.35,
                      fontSize: '14px',
                      '.MuiSelect-select': {
                        py: '5.5px'
                      },
                      color: '#696969',
                      minWidth: { xxs: 0, md: '150px' },
                      backgroundColor: '#FFFFFF'
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    {billingStatusItems?.map((name, index) => (
                      <MenuItem
                        sx={{
                          fontSize: '14px',
                          display: !name.value ? 'none' : 'flex',

                          py: 0,
                          '.MuiMenuItem-root': {
                            py: '0px'
                          }
                        }}
                        key={name.key}
                        value={name.value}
                        autoFocus={index === 0}
                      >
                        <Checkbox checked={filterData.status.split(',').includes(name.value)} />
                        <ListItemText
                          sx={{ '.MuiTypography-root': { fontSize: '14px' } }}
                          primary={name.key}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Box sx={{ width: 120 }}>
                  <Button
                    onClick={handleClearFuntion}
                    sx={{
                      display: 'flex',
                      gap: 0.5,
                      alignItems: 'center',
                      py: 0.5,
                      whiteSpace: 'nowrap'
                    }}
                    variant="text"
                  >
                    {' '}
                    <FilterCancelIcon /> Clear filter
                  </Button>
                </Box>
              </Box>
              {/* <LoadingButton
              loading={false}
              sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
              variant="outlined">
              Download all <DownloadIcon />
            </LoadingButton> */}
            </Box>
            <Box>
              {invoicesData?.length == 0 || !invoicesData ? (
                initialLength ? (
                  <NoSearchDataAvailable
                    title="No Search Results"
                    message="Try adjusting your search or filter to find what you are looking for"
                  />
                ) : (
                  <NoDataAvailable
                    title="No Data Available"
                    message="The Risks page will be populated once the first risk is discovered."
                  />
                )
              ) : (
                <TableContainer
                  component={Paper}
                  sx={{ border: 'none', boxShadow: 'none', bgcolor: 'transparent' }}
                >
                  <Table
                    sx={{
                      minWidth: 650,
                      borderCollapse: 'separate',
                      boxShadow: 'none',
                      borderSpacing: '0px 16px',
                      border: 'none'
                    }}
                    className="p10Table"
                    aria-label="site list table"
                  >
                    <TableHead>
                      <TableRow sx={{ '.MuiTableCell-root': { border: 'none' } }}>
                        <TableCell>
                          <Typography variant="body2">Date</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">Amount</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">Invoice</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">Status</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2"></Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invoicesData?.length > 0
                        ? invoicesData?.map((row: any, index: any) => {
                            return (
                              <PlanAndBillingRow
                                row={row}
                                index={index}
                                downloadPDF={downloadPDF}
                              />
                            );
                          })
                        : null}
                    </TableBody>
                  </Table>
                  <TablePagination
                    totalCount={totalCount}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                  />
                </TableContainer>
              )}
            </Box>
          </Box>
        </>
      )}
    </>
  );
};
