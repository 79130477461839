import ArrowBackIcon from '@jibin/common/icons/ArrowBack/ArrowBack';
import ExpandLessIcon from '@jibin/common/icons/ExpandLess/ExpandLess';
import ExpandMoreIcon from '@jibin/common/icons/ExpandMore/ExpandMore';
import Style from '@jibin/common/style/Styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import * as DOMPurify from 'dompurify';
import { Editor } from '@tinymce/tinymce-react';
import { Editor as EditorDisable } from '@tinymce/tinymce-react';
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';

import { convertToHTML } from 'draft-convert';
import draftToHtmlPuri from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { SuccessConfirmation } from '@jibin/common/components/ConformationModel/SuccessConfirmation';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { PoliciesAndProceduresApi } from '../Policys.Api';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import moment from 'moment';
import { useFormik } from 'formik';
import '../../../App.css';
import DropDown from '@jibin/common/icons/Dropdown/Dropdown';
import { Divider, Menu, StepConnector, Switch } from '@mui/material';
import { DeleteTypesEnum } from '@jibin/common/utils/enums/deleteTypeEnums';
import FeedbackPopup from '@jibin/common/components/FeedbackPopup';
import { EllipsisContent } from '@jibin/common/components/EllipsisContent';
import { LoadingButton } from '@mui/lab';
import ArchiveIcon from '@jibin/common/icons/ArchiveIcon/Archive';
import DownloadIcon from '@jibin/common/icons/DownloadIcon/DownloadIcon';
import EditIconForPolicy from '@jibin/common/icons/EditIconForPolicy';
import PrintIcon from '@jibin/common/icons/PrintIcon';
import ShareIconForPolicy from '@jibin/common/icons/ShareIconForPolicy';
import theme from '@jibin/common/style/core/config/theme';
import { notifySuccess } from '@jibin/common/services/http/http-common';
import CopyUrl from '@jibin/common/components/ConformationModel/CopyUrl';
import { CompanyUserRoles } from '@jibin/common/utils/constants/ConstantName';
var debounce = require('lodash.debounce');

export const PoliciesAndProceduresTemplate: FC = (props: any) => {
  const { id } = useParams();
  const nevigate = useNavigate();
  const [docStatus, setDocStatus] = useState(0);
  const [version, setVersion] = useState(0);
  const [policyId, setPolicyId] = useState(id);
  const [policy, setPolicy] = useState({} as any);
  const [policyHistory, setPolicyHistory] = useState({} as any);
  const [contenten, setContent] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [searchParamas, setSeachParams] = useSearchParams();
  const isEdit = searchParamas.get('isEdit');
  const isTemplate = searchParamas?.get('isTemplate');
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const [convertedContent, setConvertedContent] = useState(null);
  const [isRefresh, setIsRefresh] = useState(false);

  const handleClickDropdown = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const editorRef = useRef(null);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  // const handleEditorChange = (state) => {
  //   setEditorState(state);
  //   convertContentToHTML();
  // };

  // const convertContentToHTML = () => {
  //   let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
  //   setConvertedContent(currentContentAsHTML);
  //   formik.setFieldValue('document', currentContentAsHTML);
  // };

  const onEditorStateChange = (editorState) => {
    // this.setState({ editorState });
    setEditorState(editorState);
    const htmlPuri = draftToHtmlPuri(convertToRaw(editorState.getCurrentContent()));
    formik.setFieldValue('document', htmlPuri);
  };

  const getPolicy = useCallback(() => {
    PoliciesAndProceduresApi.GetPolicyById(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid,
      policyId,
      version
    ).then(
      (q: any) => {
        setPolicy(q.data);

        formik.setFieldValue('document', q.data.data?.document);
        formik.setFieldValue('name', q.data?.data?.name);
        const blocksFromHTML = htmlToDraft(q.data.data?.document);
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        setEditorState(EditorState.createWithContent(state));

        if (version == 0) {
          setVersion(q.data?.data.version);
        }

        if (isEdit == 'true' && q.data.data?.status?.toUpperCase() != 'ARCHIVED') {
          setDocStatus(1);
        } else if (q.data.data?.status?.toUpperCase() == 'DRAFT') {
          setDocStatus(2);
        } else if (q.data.data?.status?.toUpperCase() == 'PUBLISHED') {
          setDocStatus(2);
        }
      },
      (err: any) => {}
    );
  }, [commonDetails, policyId, version]);

  const getPolicyTemplate = useCallback(() => {
    PoliciesAndProceduresApi.GetTemplateById(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid,
      policyId
    ).then(
      (q: any) => {
        setPolicy(q.data);

        formik.setFieldValue('document', q.data.data?.document);
        formik.setFieldValue('name', q.data?.data?.name);
        const blocksFromHTML = htmlToDraft(q.data.data?.document);
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        setEditorState(EditorState.createWithContent(state));

        if (version == 0) {
          setVersion(q.data?.data.version);
        }

        if (isEdit == 'true' && q.data.data?.status?.toUpperCase() != 'ARCHIVED') {
          setDocStatus(1);
        } else if (q.data.data?.status?.toUpperCase() == 'DRAFT') {
          setDocStatus(2);
        } else if (q.data.data?.status?.toUpperCase() == 'PUBLISHED') {
          setDocStatus(2);
        }
      },
      (err: any) => {}
    );
  }, [commonDetails, policyId]);

  const getPolicyHistory = useCallback(() => {
    PoliciesAndProceduresApi.GetPolicyHistoryById(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid,
      policyId
    ).then(
      (q: any) => {
        setPolicyHistory(q.data);
      },
      (err: any) => {}
    );
  }, [commonDetails, policyId]);
  const log = () => {
    formik.setFieldValue('document', editorRef.current.getContent());
  };

  const updateEdit = () => {
    const blocksFromHTML = htmlToDraft(policy.data?.document);

    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    setEditorState(EditorState.createWithContent(state));

    formik.setFieldValue('name', policy.data?.name);
    formik.setFieldValue('document', policy.data?.document);
    setDocStatus(1);
  };

  useEffect(() => {
    if (isTemplate) {
      getPolicyTemplate();
    } else {
      if (id != '00000000-0000-0000-0000-000000000000') {
        getPolicyHistory();
        getPolicy();
      } else {
        setDocStatus(1);
      }
    }

    // if (editorRef.current) {
    //   editorRef.current.editorManager.execCommand("mceRemoveEditor", true, editorRef.current.id)

    //   setTimeout(function () {
    //
    //     editorRef.current.editorManager.execCommand("mceAddEditor", true, editorRef.current.id)
    //   }, 500);

    // }
  }, [getPolicy, getPolicyHistory, isRefresh]);

  const [action, setAction] = useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setAction(event.target.value);
  };
  const [openVersion, setOpenVersion] = useState(false);
  const [isDocButtonLoading, setIsDocButtonLoading] = useState(false);
  const navigate = useNavigate();
  const handleClick = () => {
    setOpenVersion(!openVersion);
  };

  const validate = (values: any) => {
    const errors = {};

    if (!values.name) {
      errors['name'] = 'This field is Required';
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: { name: '', document: '' },
    validateOnChange: false,
    validate,
    onSubmit: useCallback(
      debounce(
        async (values: any) => {
          try {
            setIsDocButtonLoading(true);
            formik.setFieldValue('document', editorRef.current.getContent());
            let data = { name: values.name, document: editorRef.current.getContent() };
            let policyID = policyId;
            if (isTemplate) {
              const newPolicy = await PoliciesAndProceduresApi.CreateNewPolicyFromTemplate(
                commonDetails.data.user_uuid,
                commonDetails.data.company.company_uuid,
                policyID,
                { name: values.name, document: editorRef.current.getContent() }
              );

              policyID = newPolicy.data.data.policy_uuid;
              setPolicyId(policyID);
              searchParamas.delete('isTemplate');
              setSeachParams(searchParamas);

              setIsRefresh(!isRefresh);
              nevigate(PageRouteConstants.PoliciesAndProceduresTemplate + policyID);
              setVersion(0);
              setDocStatus(2);
            } else {
              if (policyID == '00000000-0000-0000-0000-000000000000') {
                PoliciesAndProceduresApi.CreatePolicy(
                  commonDetails.data.user_uuid,
                  commonDetails.data.company.company_uuid,
                  data
                ).then(
                  (q: any) => {
                    setPolicyId(q.data.data.policy_uuid);
                    postDoc(
                      { name: values.name, document: editorRef.current.getContent() },
                      q.data.data.policy_uuid
                    );
                  },
                  (err) => {}
                );
              } else {
                postDoc({ name: values.name, document: editorRef.current.getContent() }, policyID);
              }
            }
          } catch (error) {
          } finally {
            setTimeout(() => {
              setIsDocButtonLoading(false);
            }, 1000);
          }
        },
        500,
        true
      ),
      [policyId]
    )
  });

  const postDoc = (values: any, id: any) => {
    PoliciesAndProceduresApi.CreatePolicyDocument(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid,
      id,
      values
    ).then(
      (q: any) => {
        setIsRefresh(!isRefresh);
        nevigate(PageRouteConstants.PoliciesAndProceduresTemplate + id);
        setVersion(0);
        setDocStatus(2);
        // if (isTemplate == 'true') {
        //   navigate(PageRouteConstants.PoliciesAndProcedures);
        // }
        notifySuccess('Document Saved as Draft');
      },
      (err) => {}
    );
  };

  const handleSubmit = useCallback(
    debounce(
      (status: any = 'Published', e: any = '', policyID: any = policyId) => {
        let data = { status: status, comment: e };
        PoliciesAndProceduresApi.SubmitPolicyDocument(
          commonDetails.data.user_uuid,
          commonDetails.data.company.company_uuid,
          policyID,
          data
        ).then(
          (q: any) => {
            setIsRefresh(!isRefresh);
            setDocStatus(0);

            setVersion(0);
            handleClose();
            if (status === 'Published') {
              navigate(PageRouteConstants.PoliciesAndProcedures);
            }
          },
          (err) => {}
        );
      },
      500,
      true
    ),
    [policy.data, docStatus]
  );

  const handleCancel = () => {
    if (policyId != '00000000-0000-0000-0000-000000000000') {
      setDocStatus(2);
    } else {
      nevigate(PageRouteConstants.PoliciesAndProcedures);
    }
  };
  const downloadPolicy = async (id, policyName, version) => {
    try {
      const res = await PoliciesAndProceduresApi.DownloadPolicy(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        id,
        version
      );
      const blob = new Blob([res.data], { type: res.headers['content-type'] });

      // Creating an object for downloading url
      const url = window.URL.createObjectURL(blob);
      const urlName = window.URL.createObjectURL(blob);

      const a = document.createElement('a');

      a.href = urlName;

      a.download = `${policyName}-${version}.docx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      handleClose();
    } catch (error) {}
  };
  const copyUrl = (id) => {
    navigator.clipboard.writeText(
      `${window.location.hostname}${PageRouteConstants.PoliciesAndProceduresTemplate + id}`
    );
    // window.location.href
    handleClose();
    notifySuccess('Copied');
  };
  const printHTMLString = async (id, version) => {
    const printerWindow = window.open('', 'Print Window', 'width=600, height=600'); // Open a new window for printing
    printerWindow.document.open();

    printerWindow.document.write(formik.values.document);
    printerWindow.document.close();
    printerWindow.print();
    handleClose();
  };
  const handlerFunction = () => {
    if (editorRef.current.selection.getContent().length > 0)
      alert(editorRef.current.selection.getContent());
    editorRef.current.selection.setContent(
      `<span style="background: yellow;">` + editorRef.current.selection.getContent() + '</span>'
    );
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={Style.PoliciesAndProcedures.PageHeadTitle}>
          <Typography
            variant="caption"
            sx={{ color: 'primary.main', display: 'flex', alignItem: 'center', mb: 1 }}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              nevigate(PageRouteConstants.PoliciesAndProcedures);
            }}
          >
            <ArrowBackIcon fontSize="small" sx={{ mr: 0.2 }} />
            Back
          </Typography>
          <Typography variant="h2" sx={{ mb: { xs: 1, lg: 2 } }}>
            Policies and Procedures
          </Typography>
          <Typography variant="subtitle2">
            Save time and gain agility by selecting the templates you need.
          </Typography>
        </Box>
        <Box sx={{ px: 4, py: { xs: 4, md: 5 }, display: 'flex' }}>
          <Grid container rowSpacing={{ sm: 2, md: 3 }} columnSpacing={2}>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={6} md={8} lg={9}>
                  <FormControl variant="outlined" sx={{ maxWidth: '420px', width: 1, mb: 1 }}>
                    {docStatus == 1 ? (
                      <TextField
                        placeholder="Document name"
                        size="small"
                        id="name"
                        name="name"
                        value={formik.values.name || ''}
                        defaultValue={formik.values.name}
                        error={formik.errors.name ? true : false}
                        helperText={formik.errors.name ? (formik.errors.name as string) : ''}
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.errors.name = '';
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          'aria-label': 'template name'
                        }}
                        sx={{
                          input: {
                            bgcolor: 'common.white'
                          }
                        }}
                      />
                    ) : (
                      <Typography sx={{ pl: 1.8, fontWeight: 600 }} variant="subtitle2">
                        {policy.data?.name}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={4} lg={3}>
                  {policy.data?.owner && (
                    <FormControl sx={{ m: 1, width: 1 }} size="small">
                      {/* <Select
                        value={action}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem value="">Actions</MenuItem>
                        <MenuItem value={10}>Deactivate</MenuItem>
                      </Select> */}

                      <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        variant="outlined"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                        endIcon={<ExpandMoreIcon />}
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClickDropdown}
                      >
                        Actions
                      </Button>

                      <Menu
                        anchorEl={anchorEl}
                        id={'account-menu'}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5
                          }
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                      >
                        {commonDetails?.data?.company?.user_role !== CompanyUserRoles.AUDITOR && (
                          <>
                            <MenuItem
                              onClick={() => {
                                if (policy.data?.status?.toUpperCase() != 'ARCHIVED') {
                                  updateEdit();
                                }

                                handleClose();
                              }}
                            >
                              <EditIconForPolicy sx={{ mr: 2, color: '#000000' }} />

                              <Typography variant="body2">Edit</Typography>
                            </MenuItem>
                            <Divider sx={{ '&.MuiDivider-root': { m: 0 } }} />
                          </>
                        )}

                        <CopyUrl
                          title="Copy and share this page"
                          icon={<ShareIconForPolicy />}
                          handleClose={handleClose}
                          mainBtnTitle={'Share Link'}
                          url={`${window.location.hostname}${
                            PageRouteConstants.PoliciesAndProceduresTemplate +
                            policy.data?.policy_uuid
                          }`}
                          handleConfirm={() => copyUrl(policy.data?.policy_uuid)}
                        />
                        <Divider sx={{ '&.MuiDivider-root': { m: 0 } }} />
                        <MenuItem
                          onClick={() =>
                            printHTMLString(policy.data?.policy_uuid, policy.data?.version)
                          }
                        >
                          <Box sx={{ mr: 2, fontSize: '24px' }}>
                            <PrintIcon />
                          </Box>

                          <Typography variant="body2">Print</Typography>
                        </MenuItem>

                        <Divider sx={{ '&.MuiDivider-root': { m: 0 } }} />
                        <MenuItem
                          onClick={() =>
                            downloadPolicy(
                              policy.data?.policy_uuid,
                              policy.data?.name,
                              policy.data?.version
                            )
                          }
                        >
                          <DownloadIcon sx={{ mr: 2, fontSize: '24px' }} />

                          <Typography variant="body2">Download</Typography>
                        </MenuItem>
                        {commonDetails?.data?.company?.user_role !== CompanyUserRoles.AUDITOR && (
                          <>
                            <Divider sx={{ '&.MuiDivider-root': { m: 0 } }} />
                            {policy.data?.status?.toUpperCase() != 'ARCHIVED' && (
                              <SuccessConfirmation
                                checked={false}
                                handleClose={() => handleClose()}
                                handleConfirm={(e) => {
                                  handleSubmit('Archived');
                                }}
                                title="Confirm Action"
                                type={'icon'}
                                description={`Are you sure, do you want to archive "${policy.data?.name}" document?`}
                                asking={``}
                                returnData={'Published'}
                                mainBtnTitle={'Archive'}
                                mainBtnType={'primary'}
                                cancelButtonText="Cancel"
                                conformButtonText="Confirm"
                              />
                            )}

                            {policy.data?.status?.toUpperCase() == 'ARCHIVED' && (
                              <SuccessConfirmation
                                checked={false}
                                handleConfirm={(e) => {
                                  handleSubmit('Draft');
                                }}
                                handleClose={() => handleClose()}
                                title="Confirm Action"
                                type={'icon'}
                                description={`Are you sure, do you want to unarchive "${policy.data?.name}" document?`}
                                asking={``}
                                returnData={'Published'}
                                mainBtnTitle={'Unarchive'}
                                mainBtnType={'primary'}
                                cancelButtonText="Cancel"
                                conformButtonText="Confirm"
                              />
                            )}
                            {policy.data?.status?.toUpperCase() != 'ARCHIVED' && (
                              <Divider sx={{ '&.MuiDivider-root': { m: 0 } }} />
                            )}
                            {policy.data?.status?.toUpperCase() != 'ARCHIVED' && (
                              <SuccessConfirmation
                                checked={
                                  policy.data?.status?.toUpperCase() == 'PUBLISHED' ? true : false
                                }
                                handleClose={() => handleClose()}
                                handleConfirm={(e) => {
                                  if (policy.data?.status?.toUpperCase() == 'PUBLISHED') {
                                    handleSubmit('Draft');
                                  } else {
                                    handleSubmit('Published');
                                  }
                                }}
                                title="Confirm Action"
                                type={'switch'}
                                description={`Are you sure, do you want to ${
                                  policy.data?.status?.toUpperCase() == 'PUBLISHED'
                                    ? 'draft'
                                    : 'publish'
                                } "${policy.data?.name}" document?`}
                                asking={``}
                                returnData={'Published'}
                                mainBtnTitle={'Archive'}
                                mainBtnType={'primary'}
                                cancelButtonText="Cancel"
                                conformButtonText="Confirm"
                              />
                            )}
                          </>
                        )}
                      </Menu>
                    </FormControl>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <Box className="editor" sx={{ minHeight: 200, bgcolor: 'common.white' }}>
                {docStatus == 1 ? (
                  <Box>
                    <Editor
                      apiKey={process.env.REACT_APP_TINYMCE_KEY}
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      initialValue={formik.values.document}
                      disabled={docStatus != 1}
                      // onMouseUp={handlerFunction}
                      init={{
                        branding: false,
                        min_height: 500,
                        toolbar_mode: 'wrap',
                        toolbar_sticky: true,
                        statusbar: false,
                        height: 500,
                        width: '100%',
                        menubar: false,

                        plugins:
                          'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                        toolbar:
                          'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link table mergetags | align lineheight | checklist numlist bullist indent outdent | charmap',
                        content_style:
                          'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                      }}
                    />
                  </Box>
                ) : (
                  <Box>
                    <span style={{ display: 'none' }}>{docStatus}</span>
                    <Editor
                      apiKey={process.env.REACT_APP_TINYMCE_KEY}
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      initialValue={formik.values.document}
                      toolbar={false}
                      disabled={docStatus != 1}
                      init={{
                        branding: false,
                        min_height: 500,
                        toolbar_mode: 'wrap',
                        toolbar_sticky: true,
                        statusbar: false,
                        height: 500,
                        width: '100%',
                        menubar: false,

                        plugins:
                          'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                        toolbar:
                          'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                        content_style:
                          'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                      }}
                    />
                  </Box>
                )}

                {/* <Box sx={{ display: 'none' }}>
                  <EditorDisable
                    apiKey="0bzu23repy17a07bvp808uz4xy2lpqghti0g39enonxxgijr"
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    initialValue={formik.values.document}
                    disabled={true}
                    init={{
                      branding: false,
                      min_height: 500,
                      toolbar_mode: 'wrap',
                      toolbar_sticky: true,
                      statusbar: false,
                      height: 500,
                      width: '100%',
                      menubar: false,
                      toolbar: false,
                      content_style:
                        'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                      tinycomments_mode: 'embedded',
                      tinycomments_author: 'Author name',
                      mergetags_list: [
                        { value: 'First.Name', title: 'First Name' },
                        { value: 'Email', title: 'Email' }
                      ]
                    }}
                  />
                </Box> */}

                {/* {policy.data?.document} */}
                {/* <span>{convertedContent}</span> */}
              </Box>
              {policy.data?.status?.toUpperCase() != 'ARCHIVED' && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    py: 3
                  }}
                >
                  {/* <Button variant="outlined" sx={{ minWidth: 150, mr: 2 }} color="error"> Reject </Button> */}
                  {docStatus == 0 &&
                    commonDetails?.data?.company?.user_role !== CompanyUserRoles.AUDITOR && (
                      <>
                        {isTemplate ? (
                          <>
                            <Button
                              variant="outlined"
                              sx={{ minWidth: 150, mr: 2, padding: '6px 16px' }}
                              onClick={() => {
                                navigate(PageRouteConstants.PoliciesAndProcedures);
                              }}
                            >
                              {' '}
                              Close
                            </Button>
                            {policy.data?.status?.toUpperCase() != 'REJECTED' && (
                              <Button
                                variant="contained"
                                onClick={() => {
                                  updateEdit();
                                }}
                                sx={{ minWidth: 120, mr: 2, border: '1px solid #436af3' }}
                                color={props.mainBtnType}
                              >
                                Edit Template
                              </Button>
                            )}
                          </>
                        ) : (
                          <Button
                            onClick={() => {
                              updateEdit();
                            }}
                            variant="contained"
                            sx={{ minWidth: 150 }}
                          >
                            {' '}
                            Edit{' '}
                          </Button>
                        )}
                      </>
                    )}
                  {docStatus == 1 && (
                    <>
                      <Button
                        variant="outlined"
                        sx={{ minWidth: 150, mr: 2, padding: '6px 16px' }}
                        onClick={() => {
                          handleCancel();
                        }}
                      >
                        {' '}
                        Cancel{' '}
                      </Button>
                      <LoadingButton
                        loading={isDocButtonLoading}
                        variant="contained"
                        sx={{ minWidth: 150, border: '1px solid #436af3' }}
                        type="submit"
                      >
                        {' '}
                        Save{' '}
                      </LoadingButton>
                    </>
                  )}
                  {docStatus == 2 && (
                    <>
                      <Button
                        variant="outlined"
                        sx={{ minWidth: 150, mr: 2, padding: '6px 16px' }}
                        onClick={() => {
                          navigate(PageRouteConstants.PoliciesAndProcedures);
                        }}
                      >
                        {' '}
                        {isTemplate == 'true' ? 'Close' : 'Cancel'}
                      </Button>
                      {policy.data?.status?.toUpperCase() != 'REJECTED' &&
                        commonDetails?.data?.company?.user_role !== CompanyUserRoles.AUDITOR && (
                          <Button
                            variant="contained"
                            onClick={() => {
                              updateEdit();
                            }}
                            sx={{ minWidth: 120, mr: 2, border: '1px solid #436af3' }}
                            color={props.mainBtnType}
                          >
                            Edit {isTemplate == 'true' && 'Template'}
                          </Button>
                        )}
                    </>
                  )}
                </Box>
              )}
            </Grid>
            {policy.data?.owner && (
              <Grid item xs={12} md={4} lg={3}>
                <Box className="versions-card" sx={Style.versionsCard.cardWrapper}>
                  <Box sx={Style.versionsCard.userInfo}>
                    {policy?.data?.owner?.ico ? (
                      <Box
                        sx={{
                          ...Style.versionsCard.infoRound,
                          width: '45px',
                          height: '45px'
                        }}
                      >
                        <img
                          width={'100%'}
                          height="100%"
                          style={{ objectFit: 'cover', borderRadius: '50%' }}
                          src={`${process.env.REACT_APP_API_HOST}/${policy?.data?.owner?.ico}`}
                          alt=""
                        />
                      </Box>
                    ) : (
                      <Typography
                        sx={{
                          ...Style.versionsCard.infoRound,
                          width: '45px',
                          height: '45px'
                        }}
                      >
                        {policy?.data?.owner?.first_name?.charAt(0) +
                          policy?.data?.owner?.last_name?.charAt(0)}
                      </Typography>
                    )}
                    <Box sx={Style.versionsCard.infoText}>
                      <Typography variant="caption" sx={{ fontWeight: '600', display: 'block' }}>
                        Edited by
                      </Typography>
                      <Typography variant="caption" sx={{ display: 'block' }}>
                        {policy?.data?.owner?.first_name + ' ' + policy?.data?.owner?.last_name}
                      </Typography>
                      <Typography variant="caption">
                        <EllipsisContent
                          sx={{
                            fontSize: 14,
                            color: '#696969',
                            width: 'max-content',
                            maxWidth: {
                              xxs: 190,
                              md: 190
                            }
                          }}
                          text={policy?.data?.owner?.email}
                          maxTextWidth={190}
                        />
                        {/* {policy?.data?.owner?.email} */}
                      </Typography>
                    </Box>
                  </Box>

                  {policy.data?.status?.toUpperCase() == 'APPROVED' && (
                    <Box sx={Style.versionsCard.userInfo}>
                      {/* <Typography sx={Style.versionsCard.infoRound}>
                        {policy.data?.status_updated_by.first_name.charAt(0) +
                          policy.data?.status_updated_by.last_name.charAt(0)}
                      </Typography> */}

                      {policy.data?.status_updated_by.ico ? (
                        <Box sx={Style.versionsCard.infoRound}>
                          <img
                            width={'100%'}
                            height="100%"
                            style={{ objectFit: 'cover', borderRadius: '50%' }}
                            src={`${process.env.REACT_APP_API_HOST}/${policy.data?.status_updated_by.ico}`}
                            alt=""
                          />
                        </Box>
                      ) : (
                        <Typography sx={Style.versionsCard.infoRound}>
                          {policy.data?.status_updated_by?.first_name?.charAt(0) +
                            policy.data?.status_updated_by?.last_name?.charAt(0)}
                        </Typography>
                      )}

                      <Box sx={Style.versionsCard.infoText}>
                        <Typography variant="caption" sx={{ fontWeight: '600', display: 'block' }}>
                          {policy.data?.status} by
                        </Typography>
                        <Typography variant="caption" sx={{ display: 'block' }}>
                          {policy.data?.status_updated_by.first_name +
                            ' ' +
                            policy.data?.status_updated_by.last_name}
                        </Typography>
                        <Typography variant="caption">
                          {policy.data?.status_updated_by.email}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  <Box sx={Style.versionsCard.versionInfo}>
                    <Typography variant="caption">
                      Current Version:
                      <Typography component="span" variant="caption" sx={{ fontWeight: '600' }}>
                        V{policy.data?.version}
                      </Typography>
                    </Typography>
                    <Box sx={Style.versionsCard.versionStatus}>
                      <Typography variant="caption">Status:</Typography>
                      <Box
                        sx={{ display: 'flex', flexWrap: 'wrap', ml: 0.5, alignItems: 'center' }}
                      >
                        {policy.data?.status?.toUpperCase() == 'APPROVED' && (
                          <Box
                            component="span"
                            sx={{
                              ...Style.versionsCard.versionStatusIcon,
                              bgcolor: 'common.green'
                            }}
                          ></Box>
                        )}
                        {policy.data?.status?.toUpperCase() == 'DRAFT' && (
                          <Box
                            component="span"
                            sx={{
                              ...Style.versionsCard.versionStatusIcon,
                              bgcolor: '#B4C3FA'
                            }}
                          ></Box>
                        )}
                        {policy.data?.status?.toUpperCase() == 'PUBLISHED' && (
                          <Box
                            component="span"
                            sx={{
                              ...Style.versionsCard.versionStatusIcon,
                              bgcolor: '#10B981'
                            }}
                          ></Box>
                        )}
                        {policy.data?.status?.toUpperCase() == 'REJECTED' && (
                          <Box
                            component="span"
                            sx={{
                              ...Style.versionsCard.versionStatusIcon,
                              bgcolor: 'error.main'
                            }}
                          ></Box>
                        )}
                        {policy.data?.status?.toUpperCase() == 'ARCHIVED' && (
                          <Box
                            component="span"
                            sx={{
                              ...Style.versionsCard.versionStatusIcon,
                              bgcolor: '#9D9D9D'
                            }}
                          ></Box>
                        )}

                        <Typography variant="caption" sx={{ fontWeight: 600 }}>
                          {' '}
                          {policy.data?.status}
                        </Typography>
                        <Typography variant="caption" sx={{ fontSize: '10px', width: 1 }}>
                          {moment(
                            docStatus >= 0 && policy.data?.status?.toUpperCase() == 'DRAFT'
                              ? policy.data?.created_at
                              : policy.data?.status_updated_at
                          ).format('DD MMM YYYY hh:mm A')}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  {commonDetails?.data?.company?.user_role !== CompanyUserRoles.AUDITOR && (
                    <Box sx={Style.versionsCard.versionStepper}>
                      <List
                        sx={{ width: '100%', maxWidth: 1 }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                      >
                        <Box
                          onClick={handleClick}
                          sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                        >
                          {openVersion ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                          <Typography variant="caption" sx={{ fontWeight: 600 }}>
                            {' '}
                            Historical versions
                          </Typography>
                        </Box>
                        <Collapse in={openVersion} timeout="auto">
                          <Collapse in={openVersion} timeout="auto">
                            <Stepper
                              connector={<StepConnector sx={{ display: 'none' }} />}
                              orientation="vertical"
                              sx={Style.Common.stepperCustomization}
                            >
                              {policyHistory.data?.map((step, index) => (
                                <Step
                                  onClick={() => setVersion(step.version)}
                                  key={step.version}
                                  active={true}
                                  sx={
                                    step.version !== version
                                      ? {
                                          '.MuiStepIcon-root.Mui-active': {
                                            color: 'grey.500',
                                            height: '16px',
                                            width: '16px'
                                          }
                                        }
                                      : {
                                          '.MuiStepIcon-root.Mui-active': {
                                            height: '16px',
                                            width: '16px'
                                          }
                                        }
                                  }
                                >
                                  <StepLabel sx={{ py: 0 }}>
                                    {' '}
                                    <Typography
                                      variant="caption"
                                      sx={{ color: 'primary.main', fontWeight: 600 }}
                                    >
                                      Version {step.version}
                                    </Typography>{' '}
                                  </StepLabel>
                                  <StepContent
                                    sx={{
                                      margin: '0px',
                                      mb: 1,
                                      '&.MuiStepContent-root': {
                                        marginLeft: '8px'
                                      }
                                    }}
                                  >
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                      <Typography variant="caption" sx={{ fontSize: '10px' }}>
                                        Created{' '}
                                        {moment(step.created_at).format('DD MMM YYYY hh:mm A')}{' '}
                                      </Typography>
                                      <Typography variant="caption" sx={{ fontSize: '10px' }}>
                                        by {step.owner.first_name + ' ' + step.owner.last_name}
                                      </Typography>
                                    </Box>
                                  </StepContent>
                                </Step>
                              ))}
                            </Stepper>
                          </Collapse>
                        </Collapse>
                      </List>
                    </Box>
                  )}
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </form>
    </>
  );
};
export default PoliciesAndProceduresTemplate;
